// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10242(599eb4e75a)-C13/05/2024-19:05:59-B13/05/2024-19:09:20' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10242(599eb4e75a)-C13/05/2024-19:05:59-B13/05/2024-19:09:20",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10242",
  shortHash: "599eb4e75a",
  longHash: "599eb4e75a1ad55db38088759b4d56f10e48b227",
  dateCommit: "13/05/2024-19:05:59",
  dateBuild: "13/05/2024-19:09:20",
  buildType: "Ansible",
  } ;
